import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import Home from "pages/Home.js";
import About from "pages/AboutUs.js";
import Contact from "pages/ContactUs.js";
import GoogleAnalytics from "pages/GoogleAnalytics.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <GoogleAnalytics />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}