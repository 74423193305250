import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import mobile from "images/mobile.png";
import report from "images/analytic.png";
import custom from "images/custom.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-16 md:py-16`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null,
  heading = "Amazing Features",
  subheading = "Features",
  description = "Experience the power of RFID technology that ensures near-perfect accuracy in stock counts. Eliminate the costly errors associated with manual counting, and gain confidence in your inventory data."
}) => {

  const defaultCards = [
    { imageSrc: FastIconImage, title: "Faster Audits",
      description: "Auditing your inventory becomes a breeze with RFID. Reduce the time and effort spent on traditional audits, allowing your team to focus on more strategic tasks." },
    {
      imageSrc: ShieldIconImage,
      title: "Accuracy and Precision",
      description: "RFID technology provides near-perfect accuracy in stock counts. Unlike manual methods that are prone to human error, RFID ensures that each item is correctly identified and counted, leading to more reliable inventory data."
    },
    { imageSrc: mobile, title: "Mobile Compatibility", description: "Access your inventory data from anywhere, at any time, with our mobile-compatible platform. Use smartphones or tablets to perform stock counts, view inventory levels, and receive alerts, providing you with the flexibility to manage your inventory on the go." },
    { imageSrc: report, title: "Analytics", description: "Harness the power of data-driven decision-making. Our RFID Stock Count Solutions provide detailed analytics and insights into your inventory trends, helping you optimize stocking levels, anticipate demand, and identify areas for improvement." },
    { imageSrc: ReliableIconImage, title: "Cost Savings", description: "RFID stock counts can lead to cost savings in various ways, including reduced labor costs, minimized errors, optimized stocking levels, and better demand forecasting. The return on investment (ROI) can be substantial over time." },
    { imageSrc: custom, title: "Customizable Features", description:"We understand that no two businesses are the same. That's why we offer a range of customizable features that can be seamlessly integrated into your RFID stock count solution. Our dedicated team of experts works closely with you to understand your specific challenges and objectives, ensuring that the solution we provide aligns perfectly with your requirements." }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
