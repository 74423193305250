import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex justify-center max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-20 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`

export default ({textOnLeft = false}) => {
  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Navigating Tomorrow's Solutions, Today</Heading>
            <Description>Welcome to KFL Tech, your partner in revolutionizing inventory management and custom app development. Founded with a vision to transform businesses through technology, we are dedicated to delivering innovative solutions that drive efficiency, accuracy, and growth.</Description>
          </TextContent>
          <TextContent>
            <Heading>Join Us on this Journey</Heading>
            <Description>At KFL Tech, we're more than a technology provider – we're your collaborators on a journey to success. Whether you're seeking streamlined inventory management, custom app development, or both, we're here to transform your ideas into reality. Join us on this exciting adventure as we harness the power of technology to elevate your business potential.</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
