import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import Header from "../headers/light.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import MainImg from "../../images/main.jpg";
import { m } from "framer-motion";
import { useState, useEffect } from "react";
import { useForm, ValidationError } from '@formspree/react';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-8 md:py-8`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export const NavLinks = tw.div`inline-block`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

const MobileLinks = tw.div`text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const DesktopLinks = tw.div`text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;

const mobile = window.innerWidth <= 500;

export default ({ roundedHeaderButton }) => {
  const [mobile, setMobile] = useState(false);
  const [state, handleSubmit] = useForm("xgejkaqw");

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 500);
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (state.succeeded) {
    alert("Thank you for your message. We will get back to you as soon as possible.");
    window.location.href = "/";
  }

  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        {mobile ? (
          <>
            <MobileLinks>
              <a href="mailto:info@kfltech.co.uk">
                <NavLink>Email: info@kfltech.co.uk</NavLink>
              </a>
            </MobileLinks>
            <MobileLinks>
              <a href="tel:+447572572411">
                <NavLink>Phone: +44 7572572411</NavLink>
              </a>
            </MobileLinks>
          </>
        ) : (
          <DesktopLinks>
            <div tw="text-left mt-2 px-16">
              <a href="mailto:info@kfltech.co.uk">
                <NavLink>Email: info@kfltech.co.uk</NavLink>
              </a>
              <a href="tel:+447572572411">
                <NavLink>Phone: +44 7572572411</NavLink>
              </a>
            </div>
          </DesktopLinks>
        )}
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Business Solutions with <span tw="text-primary-500">Latest Technologies</span>
            </Heading>
            <Paragraph>
              <b>Invoice Management System</b>
              <br />
              Efficiently create, track, and manage invoices. Streamline billing processes, automate calculations, and track payment statuses.
              Enhance client communication, ensure accuracy, and optimize financial transparency.
              Boost productivity and professionalism with customizable templates and integrated payment options.
            </Paragraph>
            <Paragraph>
              <b>Stock Count with RFID Technology</b>
              <br />
              Are you tired of the complexities and inefficiencies of manual stock counting? Our cutting-edge RFID (Radio Frequency Identification) Stock Count Solutions are here to revolutionize the way you manage your inventory.
              Say goodbye to time-consuming and error-prone manual counting methods, and say hello to accuracy, speed, and real-time insights.
            </Paragraph>
            {/* <Actions>
              <form onSubmit={handleSubmit}>
                <input type="email" placeholder="Your E-mail Address" />
                <textarea name="message" hidden />
                <button type="submit">Request a Demo</button>
              </form>
            </Actions> */}
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={MainImg} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
